<template>
  <div>
    <PageTitle>很抱歉！您的帳號已被停用，無法進行下單，如有相關疑問請與客服聯繫：</PageTitle>
    <div class="s-text-center s-space-y-4">
      <p>寶雅 / 寶雅醫美放<br />0800-033-168</p>
      <p>寶家放<br />0800-033-158</p>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'

  export default {
    components: { PageTitle },
  }
</script>
